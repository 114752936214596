<template>
    <ui-create
        name="cities"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwcityCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                uf: null,
                codigo_ibge: null
            },

            rows: [
                [
                    {
                        name: 'descricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Descrição',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 9,
                            xl: 10
                        }
                    },
                    // {
                    //     name: 'uf',
                    //     value: '',
                    //     type: 'string',
                    //     field: 'autocomplete',
                    //     label: 'Estado',
                    //     handle: 'default',
                    //     service: 'grupos',
                    //     serviceParams: null,
                    //     optionText: 'descricao',
                    //     options: [],
                    //     col: {
                    //         default: 12,
                    //         xs: 12,
                    //         sm: 12,
                    //         md: 2,
                    //         lg: 2,
                    //         xl: 1
                    //     },
                    // },
                    {
                        name: 'uf',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Estado',
                        handle: 'default',
                        options: [
                            'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
                            'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
                        ],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 2,
                            lg: 1,
                            xl: 1
                        },
                    },
                    {
                        name: 'codigo_ibge',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Código',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 2,
                            lg: 1,
                            xl: 1
                        },
                    }
                ],
            ],
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar cidade: ${this.id}` : 'Cadastrar Cidade'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    },

    mounted(){
        this.$root.$on('load-data-cities', (data) => {
            this.form = data
        })
    }
}
</script>